/* eslint-disable @typescript-eslint/no-unused-vars */
import Avatar from 'components/atoms/Avatar'
import Button from 'components/atoms/Button/button'
import { capitalize, lowerCase } from 'lodash'
import { RiArrowRightSFill } from 'react-icons/ri'

const Acknowledgement = ({
  visibility,
  fromEmployees,
  toEmployees,
  employees,
  setShowAcknowledgement,
  setEmployees,
  setFromEmployees,
  setToEmployees,
  currTab,
}: any) => {
  return (
    <>
      <div className="mt-10 mb-10 flex flex-col items-center">
        {currTab === 'Note To Self' ? (
          <p className="mb-4">
            Your <span className="font-bold">private note</span> has been saved for:
          </p>
        ) : (
          <p className="mb-4">
            {employees.length > 0 && (
              <>
                Your{' '}
                <span className="font-bold">
                  {visibility === 'PRIVATE_AND_MANAGER'
                    ? 'private + manager'
                    : visibility === 'MANAGER_ONLY'
                    ? 'manager only'
                    : lowerCase(visibility)}
                </span>{' '}
                Feedback has been shared with:
              </>
            )}
          </p>
        )}
        <div className="mb-4">
          {visibility === 'PRIVATE' && currTab !== 'Note To Self' && employees && fromEmployees.length === 0 && (
            <div className="space-y-4">
              <div className="flex items-center">
                <div className="flex font-semibold items-center text-gray-600 text-sm pr-2">
                  TO <RiArrowRightSFill className="text-themeGray" size={25} />
                </div>
                <div>
                  {employees.map((emp) => (
                    <div key={emp.fullName} className="flex">
                      <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                      <div className="ml-3">
                        <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                        <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {visibility === 'PRIVATE_AND_MANAGER' &&
            currTab !== 'Note To Self' &&
            employees &&
            fromEmployees.length === 0 && (
              <div className="space-y-4">
                <div className="flex items-center">
                  <div className="flex font-semibold items-center text-gray-600 text-sm pr-2">
                    ABOUT <RiArrowRightSFill className="text-themeGray" size={25} />
                  </div>
                  <div>
                    {employees.map((emp) => (
                      <div key={emp.fullName} className="flex">
                        <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                        <div className="ml-3">
                          <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                          <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex ml-7">
                  <div className="flex pt-3 text-sm pr-2">
                    <p className="pt-0.5 font-semibold text-gray-600">TO</p>{' '}
                    <RiArrowRightSFill className="text-themeGray" size={25} />
                  </div>
                  <div className="space-y-2">
                    {employees.map((emp) => (
                      <div key={emp.fullName} className="flex">
                        <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                        <div className="ml-3">
                          <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                          <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                        </div>
                      </div>
                    ))}
                    <p className="text-gray-600 font-semibold text-sm text-center">AND</p>
                    {employees.map((emp) =>
                      !emp?.manager ? null : (
                        <div key={emp?.manager?.fullName} className="flex">
                          <Avatar
                            name={emp?.manager?.fullName}
                            image={emp?.manager?.profileImageThumbnailUrl}
                            nameClass="font-semibold"
                          />
                          <div className="ml-3">
                            <p className="text-md">
                              {emp?.manager?.fullName.charAt(0).toUpperCase() + emp?.manager?.fullName.slice(1)}
                            </p>
                            <p className="text-sm text-gray-600">{employees[0]?.manager?.jobTitle}</p>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
            )}

          {visibility === 'MANAGER_ONLY' && currTab !== 'Note To Self' && employees && fromEmployees.length === 0 && (
            <div className="space-y-4">
              <div className="flex items-center">
                <div className="flex font-semibold items-center text-gray-600 text-sm pr-2">
                  ABOUT <RiArrowRightSFill className="text-themeGray" size={25} />
                </div>
                <div>
                  {employees.map((emp) => (
                    <div key={emp.fullName} className="flex">
                      <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                      <div className="ml-3">
                        <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                        <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex ml-7">
                <div className="flex pt-3 text-sm pr-2">
                  <p className="pt-0.5 font-semibold text-gray-600">TO</p>{' '}
                  <RiArrowRightSFill className="text-themeGray" size={25} />
                </div>
                <div className="space-y-2">
                  {employees.map((emp) =>
                    !emp.manager ? null : (
                      <div key={emp?.manager?.fullName} className="flex">
                        <Avatar name={emp?.manager ? emp?.manager?.fullName : ''} nameClass="font-semibold" />
                        <div className="ml-3">
                          <p className="text-md">
                            {emp?.manager?.fullName.charAt(0).toUpperCase() + emp?.manager?.fullName.slice(1)}
                          </p>
                          <p className="text-sm text-gray-600">{emp?.manager?.jobTitle}</p>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          )}

          {(visibility === 'PUBLIC' || (currTab === 'Note To Self' && employees && fromEmployees.length === 0)) && (
            <>
              {employees.map((emp) => (
                <div key={emp.id} className="mb-4 flex">
                  <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                  <div className="ml-3">
                    <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                    <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                  </div>
                </div>
              ))}
            </>
          )}

          {fromEmployees && (
            <div className="mb-6">
              {fromEmployees.map((emp, idx) => (
                <div key={emp.fullName} className="flex mb-4">
                  {idx === 0 && (
                    <div className="flex font-semibold items-center absolute -ml-20 text-gray-600 text-sm m-2">
                      FROM <RiArrowRightSFill className="text-themeGray" size={25} />
                    </div>
                  )}
                  <Avatar name={emp?.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                  <div className="ml-3">
                    <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp?.fullName.slice(1)}</p>
                    <p className="text-sm text-gray-600">{emp?.jobTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {toEmployees && (
            <div className="">
              {toEmployees.map((emp, idx) => (
                <div key={emp.fullName} className="flex mb-4">
                  {idx === 0 && (
                    <div className="flex font-semibold items-center absolute -ml-20 text-gray-600 text-sm m-2">
                      ABOUT <RiArrowRightSFill className="text-themeGray" size={25} />
                    </div>
                  )}
                  <Avatar name={emp.fullName} image={emp?.profileImageThumbnailUrl} nameClass="font-semibold" />
                  <div className="ml-3">
                    <p className="text-md">{emp.fullName.charAt(0).toUpperCase() + emp.fullName.slice(1)}</p>
                    <p className="text-sm text-gray-600">{emp.jobTitle}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <Button
          label={fromEmployees.length > 0 ? 'Request another Feedback' : 'Give Someone else Feedback'}
          onClick={() => {
            setFromEmployees([])
            setToEmployees([])
            setEmployees([])
            setShowAcknowledgement(false)
          }}
          primary
        />
      </div>
    </>
  )
}

export default Acknowledgement
