/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Disclosure } from '@headlessui/react'
import { UsersIcon } from '@heroicons/react/outline'
import classNames from 'classnames'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import CollapsedSideBar from 'components/organisms/Menu/CollapsedSideBar'
import { MasterHomeSidebar } from 'components/organisms/Menu/ExtendedSidebar'
import MobileNav from 'components/organisms/Menu/Mobile'
import { HttpRequest } from 'helpers/services'
import useIsMobile from 'hooks/useIsMobile'
import useKeyPress from 'hooks/useKeyPress'
import { lowerCase } from 'lodash'
import get from 'lodash/get'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { NavLink } from 'react-router-dom'
import { setPersonalInfo } from 'state/redux/actions/user.actions'

const NavChildrens = ({ subItems, setSubSelectedItem }: any) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname
  return (
    <span>
      {subItems.map((subItem: any, idx: number) => (
        <a
          key={idx}
          onClick={() => {
            navigate(subItem.href)
            setSubSelectedItem(subItem.name)
          }}
          className={classNames(
            subItem.href === pathName ? 'bg-white shadow' : 'hover:text-blue-500',
            idx === subItems.length - 1 ? 'mb-4' : '',
            'group w-full transition-all flex text-sm items-center pl-11 pr-2 h-8 font-medium text-black cursor-pointer'
          )}
        >
          {subItem.name}
        </a>
      ))}
    </span>
  )
}

const truncate = (str) => {
  const maxLength = 12
  if (str?.length > maxLength) {
    return str?.slice(0, maxLength) + '...'
  } else {
    return str
  }
}

const navigation_admin = (company, user) => [
  {
    id: '1',
    title: `${truncate(company?.companyName)} Admin`,
    name: 'People1',
    icon: UsersIcon,
    current: true,
    href: '',
    children: [],
  },
  {
    id: '1',
    title: 'Platform',
    name: 'People1',
    icon: UsersIcon,
    current: true,
    children: [
      {
        name: 'People',
        href: '#',
        subNav: [
          { name: 'Employees', href: `/admin/${company?.companyId}/employees` },
          { name: 'Departments', href: `/admin/${company?.companyId}/departments` },
          { name: 'Teams', href: `/admin/${company?.companyId}/teams` },
          { name: 'Permissions', href: `/admin/${company?.companyId}/permissions` },
        ],
      },
      {
        name: 'Company',
        href: '#',
        subNav: [
          { name: 'Profile', href: `/admin/${company?.companyId}/company/profile` },
          { name: 'Values', href: `/admin/${company?.companyId}/company/values` },
          { name: 'Saml SSO', href: `/admin/${company?.companyId}/company/saml` },
        ],
      },
      {
        name: 'Tasks',
        href: '#',
        subNav: [{ name: 'Onboarding', href: `/admin/${company?.companyId}/tasks/onboarding` }],
      },
      {
        name: 'Meetings',
        href: '#',
        subNav: [
          { name: 'Guidance', href: `/admin/${company?.companyId}/company/meeting/guidance` },
          { name: 'Templates', href: `/admin/${company?.companyId}/company/meeting/templates` },
        ],
      },
    ],
  },
  {
    id: '2',
    title: 'Performance',
    name: 'Performance',
    icon: UsersIcon,
    current: true,
    children: [
      // {
      //   name: 'Goals',
      //   href: '#',
      //   subNav: [
      //     { name: 'Cycles', href: `/admin/${company?.companyId}/goals/goal-cycles` },
      //     { name: 'Tags', href: `/admin/${company?.companyId}/goal-tags` },
      //   ],
      // },`/admin/${company?.companyId}/employee/bonus`
      {
        name: 'Bonus',
        href: '#',
        subNav: [
          // { name: 'Calculation From Sheet ', href: `/admin/${company?.companyId}/employee/bonus-sheet` },
          { name: 'Calculation', href: `/admin/${company?.companyId}/employee/bonus-sheet` },
          { name: 'Department Pool', href: `/admin/${company?.companyId}/department/bonus-pool` },
        ],
      },
      {
        name: 'Assessments',
        href: '#',
        subNav: [
          { name: 'Assessment Cycles', href: `/admin/${company?.companyId}/reviews/review-cycles` },
          { name: 'Five Star Rating', href: `/admin/${company?.companyId}/reviews/rating-system` },
        ],
      },
      {
        name: 'Surveys',
        href: '#',
        subNav: [
          { name: 'Create Survey', href: `/admin/${company?.companyId}/surveys/create` },
          { name: 'List Surveys', href: `/admin/${company?.companyId}/surveys/history` },
          { name: 'Templates Library', href: `/admin/${company?.companyId}/surveys/templates` },
        ],
      },
      {
        name: 'AI Assistant',
        href: '#',
        subNav: [{ name: 'Company Context', href: `/admin/${company?.companyId}/ai/context` }],
      },
    ],
  },
]

const navigation_settings = [
  {
    id: '1',
    title: 'YOUR SETTINGS',
    name: 'YOUR SETTINGS',
    icon: UsersIcon,
    current: true,
    children: [
      {
        name: 'Profile',
        href: '/settings/profile',
        subNav: null,
      },
      {
        name: 'Education',
        href: '/settings/education',
        subNav: null,
      },
      {
        name: 'Skill',
        href: '/settings/skill',
        subNav: null,
      },
      {
        name: 'Account',
        href: '/settings/account',
        subNav: null,
      },
    ],
  },
]

export const NavSidebar = ({
  isSidebarOpen,
  setIsSidebarOpen,
  customElements = null,
  onlyMainSidebar = false,
}: {
  isSidebarOpen: boolean
  customElements?: React.ReactNode
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  onlyMainSidebar?: boolean
}) => {
  const { companyId }: any = useParams()

  const dispatch: any = useDispatch()
  const user: any = useSelector((state) => get(state, 'user.user'))
  const [selectedCompany, setSelectedCompany]: any = useState()

  const getLocalCompany = () => {
    let company = localStorage.getItem('company')
    if (company) {
      return JSON.parse(localStorage.getItem('company'))
    } else {
      return {}
    }
  }

  const companyUserInfo = getLocalCompany()

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  useEffect(() => {
    HttpRequest('GET', `/user`)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const personalInfo = res?.data?.data
          dispatch(setPersonalInfo(personalInfo))
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const location = useLocation()
  const path = location.pathname
  const url = window.location.origin + location.pathname + location.search + location.hash

  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [subSelectedItem, setSubSelectedItem] = useState(url.includes('employees') && 'Employees')

  const navigation = () => {
    switch (true) {
      case path.includes('admin'):
        return navigation_admin(selectedCompany, companyUserInfo)
      case path.includes('settings'):
        return navigation_settings

      default:
        return navigation_admin(selectedCompany, companyUserInfo)
    }
  }

  const commonEl = navigation().map((item, idx) =>
    !item.children ? (
      <div className={' px-2'} key={idx}>
        <NavLink
          to={item.href}
          className={classNames(
            item.current
              ? 'bg-secondary text-gray-900'
              : 'bg-transparent text-gray-600 hover:bg-gray-50 hover:text-gray-900',
            'group w-full flex items-center pl-2 py-2 text-sm font-medium rounded-md'
          )}
        >
          {item.name}
        </NavLink>
      </div>
    ) : (
      <div key={idx} className={`mb-2`}>
        <h4
          className={`${
            idx === 0
              ? 'capitalize text-gray-600 flex items-center gap-2 cursor-pointer'
              : 'uppercase text-themeGray z-0'
          } px-8 text-sm font-semibold`}
        >
          {idx === 0 ? (
            <Tooltip position={'bottom'} text={selectedCompany?.companyName}>
              <div className="mb-4">{item.title}</div>
            </Tooltip>
          ) : (
            <></>
          )}
        </h4>
        {item.children.map((child, idx) => {
          if (!child.subNav) {
            return (
              <div className={''} key={idx}>
                <NavLink
                  to={child.href}
                  className={classNames(
                    url.includes(lowerCase(child.name)) ? 'bg-white shadow' : 'hover:text-blue-500',
                    'w-full flex items-center px-8 font-semibold text-sm py-2 cursor-pointer text-left tracking-normal focus:outline-none'
                  )}
                >
                  {child.name}
                </NavLink>
              </div>
            )
          } else
            return (
              <Disclosure as="div" key={idx + `${idx}`} className="" defaultOpen={true}>
                {({ open }) => (
                  <div className="">
                    <div className="">
                      <Disclosure.Button
                        className={classNames(
                          'group w-full flex items-center font-medium px-8 my-2 text-left text-base tracking-normal rounded-md focus:outline-none'
                        )}
                      >
                        <span className="text-sm font-semibold">{child.name}</span>
                        <svg
                          className={classNames(
                            open ? '-rotate-90' : 'rotate-90',
                            'ml-3 transition-transform text-gray-600 flex-shrink-0 h-5 w-5 transform group-hover:text-gray-400  ease-in-out duration-150'
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                    </div>
                    <div
                      style={{ maxHeight: open ? '50rem' : '0rem' }}
                      className={`overflow-hidden transform ${
                        open ? 'translate-y-0 opacity-100 ' : '-translate-y-3 opacity-90'
                      } duration-300 transition-all`}
                    >
                      <NavChildrens
                        open={open}
                        subSelectedItem={subSelectedItem}
                        setSubSelectedItem={setSubSelectedItem}
                        subItems={child.subNav}
                      />
                    </div>
                  </div>
                )}
              </Disclosure>
            )
        })}
      </div>
    )
  )

  const renderDynamicData = () => {
    switch (true) {
      case path.includes('test'):
        return commonEl
      case path.includes('profile'):
        return customElements || commonEl
      case path.includes('people'):
        return customElements || commonEl
      case path.includes('meeting'):
        return customElements || commonEl
      case path.includes('review'):
        return customElements || commonEl
      case path.includes('explore'):
        return customElements
      case path.includes('analytics'):
        return customElements
      default:
        return commonEl
    }
  }

  // Sidebar collapse/expand with '[' keystroke
  const bracketOpen = useKeyPress('[')

  useEffect(() => {
    if (bracketOpen) {
      setSecondarySidebarOpen((prev) => !prev)
    }
  }, [bracketOpen])
  const [mainSidebarOpen, setMainSidebarOpen] = useState(true)
  const [secondarySidebarOpen, setSecondarySidebarOpen] = useState(true)

  return (
    <React.Fragment>
      {isMobile && <MobileNav subnav={renderDynamicData} />}
      <div style={{ display: isMobile ? 'none' : 'flex', zIndex: 4002 }} className="flex font-roboto relative">
        {mainSidebarOpen && !path.includes('home') ? (
          <CollapsedSideBar
            customElements={[]}
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
            mainSidebarOpen={mainSidebarOpen}
            setMainSidebarOpen={setMainSidebarOpen}
          />
        ) : (
          <MasterHomeSidebar
            customElements={[]}
            setIsSidebarOpen={setIsSidebarOpen}
            isSidebarOpen={isSidebarOpen}
            mainSidebarOpen={mainSidebarOpen}
            setMainSidebarOpen={setMainSidebarOpen}
          />
        )}
        {!onlyMainSidebar && (
          <div className="flex group">
            <>
              {!path.includes('home') && (
                <>
                  <aside
                    style={{
                      maxWidth: secondarySidebarOpen ? '14rem' : '0rem',
                      minWidth: secondarySidebarOpen ? '14rem' : '0rem',
                      zIndex: 2,
                    }}
                    className={`sidebar
          ${
            secondarySidebarOpen ? 'opacity-100 scale-100 bg-gray-100 ' : 'scale-90 opacity-80'
          } inset-y-0 overflow-hidden left-0 w-70 overflow-y-auto  transition-all duration-500 ease-out whitespace-nowrap transform`}
                  >
                    <div className="mt-5 flex-grow flex flex-col">
                      <nav className="flex-1 gap-y-1 bg-gray-100" aria-label="Sidebar">
                        {renderDynamicData()}
                      </nav>
                    </div>
                  </aside>
                  <div className={`${!secondarySidebarOpen && 'w-6 ml-1'} bg-secondary group`}>
                    <div className={`${!secondarySidebarOpen && 'opacity-100'} opacity-0 group-hover:opacity-100`}>
                      <div
                        className={`w-6 absolute h-6 flex justify-center items-center text-xs rounded-full cursor-pointer bg-themeBlue2 text-themeBlue border-2 border-themeBlue font-bold ${
                          secondarySidebarOpen ? '-ml-2 mt-4' : 'ml-3 mt-4 mr-0'
                        }`}
                        style={secondarySidebarOpen ? { zIndex: 40 } : { zIndex: 2 }}
                        onClick={() => setSecondarySidebarOpen(!secondarySidebarOpen)}
                      >
                        <Tooltip text={setSecondarySidebarOpen ? 'Collapse' : 'Expand'}>
                          {secondarySidebarOpen ? <MdArrowBackIosNew /> : <MdArrowForwardIos />}
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
        )}
      </div>
    </React.Fragment>
  )
}
