import Modal from 'components/atoms/Modal'
import { ReactComponent as GoogleLogo } from 'assets/logo/google.svg'
import { ReactComponent as OutlookLogo } from 'assets/logo/outlook.svg'

const CalendarModal = ({
  showModal,
  setShowModal,
  isGoogleCalendarIntegrated,
  googleCalendarUrl,
  outlookCalendarUrl,
  userEmail,
  isOutlookCalendarIntegrated,
  outlookEmail,
  disconnectGoogleCalendar,
  disconnectOutlookCalendar,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  isGoogleCalendarIntegrated?: boolean
  googleCalendarUrl?: string
  isOutlookCalendarIntegrated?: boolean
  outlookCalendarUrl?: string
  userEmail?: string
  outlookEmail?: string
  disconnectGoogleCalendar?: () => void
  disconnectOutlookCalendar?: () => void
}) => {
  const onCancel = () => {
    setShowModal(false)
  }

  return (
    <Modal
      fixedOverflow
      max={{ w: 152 }}
      hwClassName={'max-w-152'}
      header={'Sync Calendar'}
      open={showModal}
      setOpen={onCancel}
    >
      <div className="my-4 text-center">
        <h1 className="text-xl font-bold">Connect to a Calendar</h1>
      </div>

      <div className="flex gap-5 justify-center items-center my-12">
        <div
          onClick={() => {
            isGoogleCalendarIntegrated ? disconnectGoogleCalendar() : (window.location.href = googleCalendarUrl)
          }}
          style={{ width: '220px', height: '180px' }}
          className="cursor-pointer transform transition-all hover:border-blue-400 hover:scale-105 flex flex-col items-center justify-center rounded-lg bg-gray-100 border-dashed border-2 border-gray-400"
        >
          <GoogleLogo className={'w-22 h-22 pt-2'} />
          {isGoogleCalendarIntegrated ? (
            <p className="cursor-pointer text-sm rounded-b-lg text-center bg-red-500 text-white py-1 w-full font-semibold">
              disconnect
            </p>
          ) : (
            <p className="text-center px-4 py-3 w-full font-semibold">Google Calendar</p>
          )}
        </div>
        <div
          onClick={() => {
            isOutlookCalendarIntegrated ? disconnectOutlookCalendar() : (window.location.href = outlookCalendarUrl)
          }}
          style={{ width: '220px', height: '180px' }}
          className="cursor-pointer transform transition-all hover:border-blue-400 hover:scale-105 flex flex-col items-center justify-center rounded-lg bg-gray-100 border-dashed border-2 border-gray-400"
        >
          <OutlookLogo className={'w-22 h-22'} />
          {isOutlookCalendarIntegrated ? (
            <p className="cursor-pointer text-sm rounded-b-lg text-center bg-red-500 text-white py-1 w-full font-semibold">
              disconnect
            </p>
          ) : (
            <p className="text-center px-4 py-3 w-full font-semibold">Outlook Calendar</p>
          )}
        </div>
      </div>
      {isGoogleCalendarIntegrated && (
        <p className="text-center px-4 py-3 w-full font-medium">
          Your Google Calendar is synced with the account: <span className="font-bold">{userEmail}</span>{' '}
        </p>
      )}
      {isOutlookCalendarIntegrated && (
        <p className="text-center px-4 py-3 w-full font-medium">
          Your Outlook Calendar is synced with the account: <span className="font-bold">{outlookEmail}</span>{' '}
        </p>
      )}
    </Modal>
  )
}

export default CalendarModal
