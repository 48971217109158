/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { DashboardLayout } from 'containers/Layouts/DashboardLayout'
import { BsFilter } from 'react-icons/bs'
import Button from 'components/atoms/Button/button'
import SearchBar from 'components/atoms/SearchBar'
import { get, isEmpty, upperCase } from 'lodash'
import ExploreGoalList from 'components/organisms/Goals/ExploreGoalList'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HttpRequest } from 'helpers/services'
import Pagination from 'components/organisms/Pagination/Pagination'
import NestedDropdown2 from 'components/atoms/Dropdown/NestedDropdown2'
import { ExploreHooks } from 'components/organisms/Goals/ExploreHooks/ExploreHooks'
import { priorityList as PRIORITY_LIST, status as STATUS_LIST } from 'pages/Goal/GoalCreate/DefaultList'
import Avatar from 'components/atoms/Avatar'
import { useMeetings } from 'components/templates/Meeting/ContextApi/MeetingContext'
import moment from 'moment'
import { Tabs2 } from 'components/organisms/Tabs/Tabs'
import useTabs from 'components/organisms/Tabs/useTabs'
import Dropdown3 from 'components/atoms/Dropdown/Dropdown3'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import { FaCalendarAlt } from 'react-icons/fa'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import { AssignDropdown } from 'components/templates/Meeting/OneOne/Dialogs/AssignDropdown'
import { useCompanyTools } from 'hooks/useCompanyTools'

export const INITIAL_DATE_RANGE = {
  startDate: moment().startOf('year').toDate(),
  dueDate: moment().endOf('year').toDate(),
}

const Explore = () => {
  const user: any = useSelector((state) => get(state, 'user.user'))

  const [selectedCompany, setSelectedCompany]: any = useState(null)

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const { companyId }: any = useParams()
  const isSubGoalPage = pathname.includes('subgoal')
  const isDepartment = pathname.includes('department')
  const isTeam = pathname.includes('team')
  const isUser = pathname.includes('user')
  const subGoalId = pathname.split('/')[5]
  const departmentId = pathname.split('/')[5]
  const teamId = pathname.split('/')[5]
  const userId = pathname.split('/')[5]

  const [selected, setSelected] = useState('myGoal')
  const [filter, setFilter]: any = useState({
    id: 'a',
    name: 'Any',
  })

  const [initialized, setInitialized] = useState(false)
  const [goals, setGoals] = useState([])
  const [fetching, setFetching] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [pagination, setPagination] = useState<{
    currentPage: number
    pageSize: number
    totalPage: number
    totalCount: number
  }>({
    currentPage: 0,
    pageSize: 0,
    totalPage: 0,
    totalCount: 0,
  })

  const [searchInput, setSearchInput] = useState('')
  const [headerLabel, setHeaderLabel] = useState<any>({})

  const [ownerList, setOwnerList] = useState([])
  const [supporterList, setSupporterList] = useState([])
  const [departmentList, setDepartmentList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [ownerManagerList, setOwnerManagerList] = useState([])
  const [ownerDepartmentList, setOwnerDepartmentList] = useState([])
  const [ownerTeamList, setOwnerTeamList] = useState([])
  const [typeDepartmentList, setTypeDepartmentList] = useState([])
  const [typeTeamList, setTypeTeamList] = useState([])
  const [goalCycleList, setGoalCycleList] = useState([])
  const [assessmentCycleList, setAssessmentCycleList] = useState([])
  const [goalStatistic, setGoalStatistic] = useState([])
  const [listType, setListType] = useState('cascading')

  const [ownerNameList, setOwnerNameList] = useState([])
  const [supporterNameList, setSupporterNameList] = useState([])
  const [ownersDepartmentIds, setOwnersDepartmentIds] = useState([])
  const [ownersTeamIds, setOwnersTeamIds] = useState([])
  const [ownersManagers, setOwnersManagers] = useState([])
  const [ownersDirectReport, setOwnersDirectReport] = useState(false)
  const [typeIndividual, setTypeIndividual] = useState(false)
  const [typeAny, setTypeAny] = useState(true)
  const [typeCompany, setTypeCompany] = useState(false)
  const [typeDepartment, setTypeDepartment] = useState(false)
  const [typeTeam, setTypeTeam] = useState(false)
  const [typeDepartments, setTypeDepartments] = useState([])
  const [typeTeams, setTypeTeams] = useState([])
  const [visibilityPublic, setVisibilityPublic] = useState(false)
  const [visibilityPrivate, setVisibilityPrivate] = useState(false)
  const [priorityList, setPriorityList]: any = useState(PRIORITY_LIST)
  const [tagFilterList, setTagFilterList] = useState([])
  const [tagList, setTagList] = useState([])
  const [statusList, setStatusList]: any = useState(STATUS_LIST)

  const [startDate, setStartDate] = useState(null)
  const [dueDate, setDueDate] = useState(null)
  const [showDateModule, setShowDateModule] = useState(false)
  const [quarters, setQuarters] = useState([])
  const [initGoal, setInitGoal] = useState(false)

  const [nameSearchInput, setNameSearchInput] = useState('')
  const [supporterSearchInput, setSupporterSearchInput] = useState('')
  const [onNameChange, setOnNameChange] = useState(true)
  const [nameListNotFound, setNameListNotFound] = useState(false)
  const [managerSearchInput, setManagerSearchInput] = useState('')
  const [managerListNotFound, setManagerListNotFound] = useState(false)
  const [departmentSearch, setDepartmentSearch] = useState('')
  const [teamSearch, setTeamSearch] = useState('')
  const [departmentListNotFound, setDepartmentListNotFound] = useState(false)
  const [teamListNotFound, setTeamListNotFound] = useState(false)
  const [company, setCompany]: any = useState()
  const [employeeId, setEmployeeId] = useState()

  const [goalCycle, setGoalCycle] = useState([])
  const [cycleSearchInput, setCycleSearchInput] = useState('')
  const [showCycleSuggestions, setShowCycleSuggestions] = useState(false)
  const [numberOfEmployees, setNumberOfEmployees] = useState(false)
  const [itemsCount, setItemsCount]: any = useState(null)
  const [supporterEmp, setSupporterEmp]: any = useState()
  const [ownerSearchQuery, setOwnerSearchQuery] = useState('')
  const [dropdownLoader, setDropdownLoader] = useState(false)
  const [employeeList, setEmployeeList]: any = useState()
  const [selectedCycle, setSelectedCycle]: any = useState()

  const { fetchAssignedItems } = useOneOnOneTools()

  const {
    sidebarContent,
    getChipItems,
    exploreLoader,
    filterOwnersItems,
    filterSupportersItems,
    filterTypeItems,
    filterStatusItems,
    onFilterCheck,
    onFilterUncheck,
    ownerEmp,
    setOwnerEmp,
    setDisableSidebar,
  } = ExploreHooks()

  const size = 50

  const { chipList, countStatus, showStatus, ownersIndividualCount, supportersIndividualCount } = getChipItems({
    ownerNameList,
    supporterNameList,
    ownersManagers,
    ownersDepartmentIds,
    ownersTeamIds,
    ownersDirectReport,
    typeIndividual,
    typeCompany,
    visibilityPublic,
    visibilityPrivate,
    priorityList,
    typeDepartments,
    statusList,
    tagFilterList,
    typeDepartment,
    typeTeam,
    typeTeams,
    typeAny,
  })

  const onCycleChipCancel = (item: any) => {
    const newCycle = goalCycle.filter((cycle) => cycle?.id !== item?.id)
    setGoalCycle(newCycle)
  }

  const removeCycleSearchAction = () => {
    setCycleSearchInput('')
  }

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
        setEmployeeId(company.employeeId)
      }
    })
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  const [rooms, setRooms]: any = useState()
  const [showMoreOne, setShowMoreOne] = useState(false)
  const { fetchMeetings, fetchMeetingContext } = useMeetings()

  const isOwner = chipList?.[0]?.text !== 'Anyone' && chipList?.[0]?.title === 'owner'

  let peopleTab = [
    { name: 'Overview' },
    { name: 'Meetings' },
    { name: 'Tasks' },
    { name: 'Goals' },
    { name: 'Feedback' },
  ]
  let deptTab = [{ name: 'Overview' }, { name: 'Meetings' }, { name: 'Tasks' }, { name: 'Goals' }]
  let tab = isOwner ? peopleTab : deptTab
  let setTab = 'Goals'
  const { curTab, setCurTab, helpers } = useTabs(tab, setTab)
  const [onOverview, onOneS, onAssigned, onGoals, onFeedback] = helpers

  useEffect(() => {
    if (companyId) {
      fetchMeetingContext(companyId)
    }
  }, [companyId])

  useEffect(() => {
    setRooms(fetchMeetings)
  }, [fetchMeetings])

  useEffect(() => {
    if (pathname.includes('team')) {
      setOwnerEmp('Anyone')
    }
    if (isUser && headerLabel?.id !== undefined && !pathname.includes('team')) {
      setOwnerEmp({
        id: headerLabel.id,
        name: headerLabel.name,
        isActive: true,
        isDisplay: false,
        title: headerLabel.title,
        type: headerLabel.type,
      })
    }
  }, [isUser, pathname, headerLabel])

  const onChipClose = (item) => {
    if (item?.title === 'owner') {
      setSelected('all')
      setOwnersManagers([])
      setOwnersDepartmentIds([])
      setOwnersTeamIds([])
      setOwnerNameList(ownerList)
      setOwnerManagerList(ownerList)
      setOwnerDepartmentList(departmentList)
      setOwnerTeamList(teamList)
      setOwnersDirectReport(false)
      setHeaderLabel({ name: 'Explore goals', title: '' })
    }
    if (item?.title === 'type' && !item?.text.includes('Dept goal')) {
      setTypeIndividual(false)
      setTypeCompany(false)
      setTypeDepartmentList(departmentList)
    }

    if (item?.title === 'type' && !item?.text.includes('Team goal')) {
      setTypeIndividual(false)
      setTypeCompany(false)
      setTypeTeamList(teamList)
    }

    if (item?.title === 'type' && item?.text.includes('Dept goal')) {
      const tempDeptList = typeDepartments.filter((i) => i.id !== item.id)
      const updateDepartmentList = typeDepartmentList.map((i) => {
        if (item.id === i.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })
      setTypeDepartmentList(updateDepartmentList)
      setTypeDepartments(tempDeptList)
    }

    async function getNumberOfEmployees(companyId) {
      const { data }: any = await HttpRequest(
        'GET',
        '/company/employees/list',
        {},
        {
          'x-appengine-company-id': companyId,
          'x-appengine-size': -1,
        }
      )

      return data.data.length || 0
    }

    if (item?.title === 'type' && item?.text.includes('Team goal')) {
      const tempTeamList = typeTeams.filter((i) => i.id !== item.id)
      const updateTeamList = typeTeamList.map((i) => {
        if (item.id === i.id) {
          return {
            ...i,
            isActive: false,
          }
        } else {
          return i
        }
      })

      setTypeTeamList(updateTeamList)
      setTypeTeams(tempTeamList)
    }

    if (item?.title === 'visibility') {
      setVisibilityPrivate(false)
      setVisibilityPublic(false)
    }
    if (item?.title === 'priority') {
      setPriorityList(PRIORITY_LIST)
    }
    if (item?.title === 'supporters') {
      setSupporterNameList(supporterList)
    }
    if (item?.title === 'tag') {
      setTagFilterList(tagList)
    }
    if (item?.title === 'status') {
      setStatusList(STATUS_LIST)
    }

    if (item?.title === 'all') {
      setOwnersManagers([])
      setOwnersDepartmentIds([])
      setOwnersTeamIds([])
      setOwnerNameList(ownerList)
      setSupporterNameList(supporterList)
      setOwnerManagerList(ownerList)
      setOwnerDepartmentList(departmentList)
      setOwnerTeamList(teamList)
      setOwnersDirectReport(false)
      setTypeIndividual(false)
      setTypeCompany(false)
      setTypeDepartmentList(departmentList)
      setTypeTeamList(teamList)
      setTypeDepartments([])
      setTypeTeams([])
      setVisibilityPrivate(false)
      setVisibilityPublic(false)
      setPriorityList(PRIORITY_LIST)
      setTagFilterList(tagList)
      setStatusList(STATUS_LIST)
    }
  }

  const ownersItems = filterOwnersItems({
    ownersManagers,
    ownersDepartmentIds,
    ownersDirectReport,
    ownerNameList,
    ownersIndividualCount,
    nameListNotFound,
    nameSearchInput,
    setNameSearchInput,
  })

  const supportersItems = filterSupportersItems({
    supportersIndividualCount,
    nameListNotFound,
    supporterNameList,
    supporterSearchInput,
    setSupporterSearchInput,
  })

  const typeItems = filterTypeItems({
    typeDepartments,
    typeIndividual,
    typeCompany,
    setTypeIndividual,
    typeDepartmentList,
    setTypeCompany,
    typeTeams,
    typeTeamList,
    typeAny,
    setTypeAny,
    setTypeTeam,
    setTypeTeams,
    setTypeDepartment,
    setTypeDepartments,
    departmentSearch,
    setDepartmentSearch,
    teamSearch,
    setTeamSearch,

    setHeaderLabel,
    selectedCompany,
    numberOfEmployees,
  })

  const statusItems = filterStatusItems({
    countStatus,
    statusList,
  })

  const fetchAssignedItemsData = () => {
    const ownerList = [...ownerNameList]
    const ownerId = ownerList.filter((owner) => owner.isActive)[0]?.id
    const data = {
      sortBy: 'dueDate',
      sortMethod: 'ASC',
      filter: {
        assignedBy: ['anyone'],
        assignedTo: [parseInt(ownerId)],
        status: 'Incomplete',
      },
    }

    const onSuccess = (_: any, pagination: any) => {
      setItemsCount(pagination.totalCount)
    }

    fetchAssignedItems(ownerId, data, companyId, onSuccess)
  }

  useEffect(() => {
    const ownerList = [...ownerNameList]
    const ownerId = ownerList.filter((owner) => owner.isActive)[0]?.id
    if (ownerNameList.length > 0) {
      !(ownerId === 'anyone') && ownerId && fetchAssignedItemsData()
    }
  }, [pathname, ownerNameList])

  useEffect(() => {
    if (!isEmpty(selectedCompany)) {
      fetchOwnerList()
    }
  }, [selectedCompany])

  useEffect(() => {
    fetchManagerList()
    fetchDepartmentList()
    fetchTeamList()
    fetchFiscalQuarters()
    fetchTagList()
    fetchAssessmentCycleList()
  }, [])

  useEffect(() => {
    if (refetch) {
      fetchGoalList()
    }
  }, [refetch])

  useEffect(() => {
    fetchAssessmentCycleList()
  }, [cycleSearchInput])

  async function getNumberOfEmployees(companyId) {
    const { data }: any = await HttpRequest(
      'GET',
      '/company/employees/list',
      {},
      {
        'x-appengine-company-id': companyId,
        'x-appengine-size': -1,
      }
    )

    return data.data.length || 0
  }

  useEffect(() => {
    getNumberOfEmployees(companyId)
      .then((numberOfEmployees) => {
        setNumberOfEmployees(numberOfEmployees)
        setFetching(false)
      })
      .catch(console.error)
  }, [])

  const source = [
    ownerNameList,
    supporterNameList,
    onNameChange,
    ownersDepartmentIds,
    ownersTeamIds,
    typeIndividual,
    typeDepartment,
    typeTeam,
    typeCompany,
    typeDepartments,
    typeTeams,
    visibilityPublic,
    visibilityPrivate,
    priorityList,
    tagFilterList,
    ownersDirectReport,
    statusList,
    searchInput,
    filter,
    subGoalId,
    listType,
  ]

  useEffect(() => {
    if (ownerNameList.length > 0 && supporterNameList.length > 0) {
      fetchGoalList()
    }
  }, source)

  const fetchGoalList = (paginate?: any) => {
    setFetching(true)
    setDisableSidebar(true)
    const commonHeader = {
      'x-appengine-size': size,
      'x-appengine-page': isEmpty(paginate) ? 1 : paginate.currentPage,
      'x-appengine-company-id': companyId,
      'x-appengine-parent-id': isSubGoalPage ? subGoalId : null,
    }

    const owners = {}
    const supporters = {}
    const visibilityNames = []
    const priority = []
    const tagIds = []
    let tempDirectReport = []
    const status = []
    let typeList = []
    let departmentIds = []
    let teamIds = []

    if (ownersDirectReport) {
      tempDirectReport.push(selectedCompany?.employeeId)
      owners['managers'] = tempDirectReport
    }

    let tempList = []
    ownerNameList.forEach((item) => {
      if (item?.isActive) {
        tempList.push(item?.id)
      }
    })

    if (ownerEmp) {
      const id = ownerEmp?.id === 'Anyone' || ownerEmp === 'Anyone' ? 'anyone' : ownerEmp?.id
      owners['ids'] = [id]
    } else {
      owners['ids'] = isUser ? [headerLabel?.id] : [selectedCompany?.employeeId]
    }

    let tempSupporterList = []
    supporterNameList.forEach((item) => {
      if (item?.isActive) {
        tempSupporterList.push(item?.id)
      }
    })

    if (supporterEmp) {
      supporters['ids'] = [supporterEmp?.id]
    } else {
      supporters['ids'] = ['anyone']
    }

    if (ownersDepartmentIds.length > 0) {
      const tempList = ownersDepartmentIds.map((item) => item.id)
      owners['departmentIds'] = tempList
    }

    if (ownersTeamIds.length > 0) {
      const tempList = ownersTeamIds.map((item) => item.id)
      owners['teamIds'] = tempList
    }

    if (ownersManagers.length > 0) {
      const list = ownersManagers.map((item) => item.id)
      owners['managers'] = tempDirectReport.concat(list)
    }

    if (typeIndividual) {
      typeList.push('INDIVIDUAL')
    }

    if (typeCompany) {
      typeList.push('COMPANY')
    }

    if (typeDepartment && typeDepartments.length > 0) {
      departmentIds = typeDepartments.map((item) => item.id)
      typeList.push('DEPARTMENT')
    }

    if (typeTeam && typeTeams.length > 0) {
      teamIds = typeTeams.map((item) => item.id)
      typeList.push('TEAM')
    }

    if (visibilityPublic) {
      visibilityNames.push('PUBLIC')
    }

    if (visibilityPrivate) {
      visibilityNames.push('PRIVATE')
    }

    if (priorityList.length !== 0) {
      priorityList.forEach((item) => {
        if (item.isActive) {
          priority.push(item.name)
        }
      })
    }

    if (tagFilterList.length !== 0) {
      tagFilterList.forEach((item) => {
        if (item.isActive) {
          tagIds.push(item.id)
        }
      })
    }

    if (showStatus.length !== 0) {
      showStatus.forEach((item) => {
        if (item === 'Any') item = 'All active'
        status.push(upperCase(item).split(' ').join('_'))
      })
    }

    const data: any = {}

    if (!isEmpty(owners)) {
      data['owners'] = owners
    }

    if (!isEmpty(supporters)) {
      data['supporters'] = supporters
    }

    if (!isEmpty(typeList)) {
      data['type'] = {
        name: typeList,
      }
      if (departmentIds.length > 0) {
        data['type'] = {
          ...data?.type,
          departmentIds: departmentIds,
        }
      }

      if (teamIds.length > 0) {
        data['type'] = {
          ...data?.type,
          teamIds: teamIds,
        }
      }
    }

    if (!isEmpty(visibilityNames)) {
      data['visibility'] = {
        names: visibilityNames,
      }
    }

    if (priority.length !== 0) {
      data['priority'] = { names: priority }
    }

    if (tagIds.length !== 0) {
      data['tags'] = { ids: tagIds }
    }

    if (status.length !== 0) {
      data['status'] = { names: status }
    }

    if (!isEmpty(selectedCycle)) {
      if (selectedCycle?.name === 'No cycle set') {
        data['reviewCycleId'] = 'NO_GOAL_CYCLE_SET'
      } else {
        if (selectedCycle?.name !== 'Any') {
          data['reviewCycleId'] = selectedCycle?.id
        }
      }
    }

    if (dueDate !== null) {
      data['dateFilter'] = {
        name: 'dueDate',
        dateFrom: startDate.toISOString(),
        dateTo: dueDate.toISOString(),
      }
    }

    if (searchInput) {
      commonHeader['search'] = searchInput
    }

    commonHeader['listType'] = listType

    HttpRequest('POST', '/goal/list', { filter: data }, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const updateCountAndPagination = () => {
            if (data?.data?.pagination) {
              setPagination(data?.data?.pagination)
            }
          }
          const goals = data?.data?.goals
          if (goals) {
            updateCountAndPagination()
            setGoals(goals)
            setGoalStatistic(data?.data?.goalStatistic)
            setRefetch(false)
            setInitGoal(true)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
        setTimeout(() => {
          setFetching(false)
          setDisableSidebar(false)
        }, 1000)
      })
  }

  const fetchEmployeeList = () => {
    setDropdownLoader(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': -1,
      type: 'tasks',
    }
    if (ownerSearchQuery) {
      commonHeader['search'] = ownerSearchQuery
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setDropdownLoader(false)
          const employees = data?.data
          if (employees) {
            setEmployeeList([
              {
                id: 'anyone',
                fullName: 'Anyone',
                title: '',
                displayName: 'Anyone',
                type: `assignedBy-anyone`,
              },
              ...employees,
            ])
          }
        }
      })
      .catch((err) => {
        setDropdownLoader(false)
        console.error(err)
      })
  }

  useEffect(() => {
    fetchEmployeeList()
  }, [ownerSearchQuery])

  const onPageChange = (e: string) => {
    let paginate: any = 1
    if (e === 'Next') {
      paginate = { ...pagination, currentPage: pagination.currentPage + 1 }
    } else if (e === 'Prev') {
      paginate = { ...pagination, currentPage: pagination.currentPage - 1 }
    }
    setPagination(paginate)
    fetchGoalList(paginate)
  }

  const onCreateGoal = () => {
    navigate(`/goals/explore/${companyId}/create`)
  }

  const fetchAssessmentCycleList = () => {
    setDropdownLoader(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    if (cycleSearchInput) {
      commonHeader['search'] = cycleSearchInput
    }

    HttpRequest('GET', '/review/cycle/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        setDropdownLoader(false)
        if (data.code === 200) {
          const cycles = data?.data?.reviewCycle
          const any = { name: 'Any', id: 'any' }
          const no = { name: 'No cycle set', id: 'NO_GOAL_CYCLE_SET' }
          const updatedList = [any, no, ...cycles]
          setAssessmentCycleList(updatedList)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
        setDropdownLoader(false)
      })
  }

  const getSearchFilter = (employees, list) => {
    let result = list.filter((o1) => !employees?.some((o2) => o1.id === o2.id))
    let result2 = list.filter((o1) => employees?.some((o2) => o1.id === o2.id))
    let temp = []
    let temp2 = []
    if (result.length > 0) {
      temp = result.map((item) => ({
        ...item,
        isDisplay: false,
      }))
    }
    if (result2.length > 0) {
      temp2 = result2.map((item) => ({
        ...item,
        isDisplay: true,
      }))
    }
    return temp.concat(temp2).sort((a, b) => a.id - b.id)
  }

  useEffect(() => {
    if (!isEmpty(selectedCompany)) {
      fetchOwnerList()
    }
  }, [nameSearchInput, supporterSearchInput])

  useEffect(() => {
    if (initialized) {
      fetchManagerList()
    }
  }, [managerSearchInput])

  useEffect(() => {
    if (initialized) {
      fetchDepartmentList()
    }
  }, [departmentSearch])

  useEffect(() => {
    if (initialized) {
      fetchTeamList()
    }
  }, [teamSearch])

  const fetchOwnerList = useCallback(() => {
    let defaultCompany
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        defaultCompany = company
      }
    })

    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': -1,
      type: 'tasks',
    }
    if (nameSearchInput || supporterSearchInput) {
      commonHeader['search'] = nameSearchInput || supporterSearchInput
    }

    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          const employees = data?.data
          if (employees) {
            const tempOwnerList = employees.map((item) => ({
              id: item.id,
              name: item.fullName,
              title: item?.jobTitle,
              isActive: false,
              isDisplay: true,
              type: `owner-${item.id}`,
            }))
            const newTempOwnerList = [
              {
                id: 'anyone',
                name: 'Anyone',
                title: '',
                isActive: false,
                isDisplay: true,
                type: 'owner-anyone',
              },
              ...tempOwnerList,
            ]

            const tempSupporterList = employees.map((item) => ({
              id: item.id,
              name: item.fullName,
              title: item?.jobTitle,
              isActive: false,
              isDisplay: true,
              type: `supporter-${item.id}`,
            }))

            const newTempSupporterList = [
              {
                id: 'anyone',
                name: 'Anyone',
                title: '',
                isActive: true,
                isDisplay: true,
                type: 'supporter-anyone',
              },
              ...tempSupporterList,
            ]

            const tOwnerList = newTempOwnerList.map((owner) => {
              if (isUser ? owner.id === parseInt(userId) : owner.id === defaultCompany?.employeeId) {
                setHeaderLabel(owner)
                return {
                  ...owner,
                  isActive: true,
                }
              } else
                return {
                  ...owner,
                  isActive: false,
                }
            })

            setOwnerNameList(
              isSubGoalPage ? newTempOwnerList.sort((a, b) => a.id - b.id) : tOwnerList.sort((a, b) => a.id - b.id)
            )
            setSupporterNameList(newTempSupporterList.sort((a, b) => a.id - b.id))
            setOwnerList(isSubGoalPage ? newTempOwnerList : tOwnerList)
            setSupporterList(newTempSupporterList)
            setRefetch(true)
          }
          if (employees.length === 0) {
            setNameListNotFound(true)
          } else {
            setNameListNotFound(false)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [nameSearchInput, supporterSearchInput])

  const fetchManagerList = useCallback(() => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      type: 'tasks',
    }
    if (managerSearchInput) {
      commonHeader['search'] = managerSearchInput
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          const employees = data?.data
          if (employees) {
            if (ownerManagerList.length === 0) {
              const tempList = employees.map((item) => ({
                id: item.id,
                name: item.fullName,
                isActive: false,
                isDisplay: true,
              }))
              setOwnerManagerList(tempList)
            } else {
              const finalResult = getSearchFilter(employees, ownerManagerList)
              setOwnerManagerList(finalResult)
            }
          }
          if (employees.length === 0) {
            setManagerListNotFound(true)
          } else {
            setManagerListNotFound(false)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }, [])

  const fetchDepartmentList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-my-departments': 'true',
    }
    if (departmentSearch) {
      commonHeader['search'] = departmentSearch
    }
    HttpRequest('GET', '/company/department', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setInitialized(true)
          const departments = data?.data?.departments

          const tempList = departments.map((d) => ({
            name: d.name,
            id: d.id,
            isActive: false,
            isDisplay: true,
          }))
          setDepartmentList(tempList)
          setOwnerDepartmentList(tempList)
          setTypeDepartmentList(tempList)
          setDepartmentListNotFound(departments.length === 0)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchTeamList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-my-team': 'true',
      'x-appengine-for-search': 'true',
    }

    // Add search to headers if it exists
    if (teamSearch) {
      commonHeader['search'] = teamSearch
    }

    // Fetch data from the server
    HttpRequest('GET', '/team/get-all', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setInitialized(true)
          const teams = data?.data?.team || []

          const tempList = teams.map((d) => ({
            name: d.name,
            id: d.id,
            isActive: false,
            isDisplay: true,
          }))

          setTeamList(tempList)
          setOwnerTeamList(tempList)
          setTypeTeamList(tempList)

          setTeamListNotFound(teams.length === 0)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchFiscalQuarters = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/goal/getQuarterCycle', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const fiscal = data?.data
          if (fiscal) {
            const tempList = fiscal.map((f) => {
              return {
                id: f.id,
                name: f.name,
                value: 'f',
              }
            })
            setQuarters(tempList)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchTagList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/goal/tags', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const tempList = data?.data.map((d) => ({
            name: d.name,
            id: d.id,
          }))
          setTagList(tempList)
          setTagFilterList(tempList)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fiscalList = quarters

  const otherList = [
    {
      id: 'b1',
      name: 'No cycle set',
    },
  ]

  const allTimeList = [
    {
      id: 'a',
      name: 'Any',
    },
  ]

  const onFilterSelect = (item) => {
    if (item?.id === 'b2' || item?.id === 'b3') {
      setShowDateModule(true)
    }
    setFilter(item)
  }

  const setAllActive = (checked) => {
    const updatedStatus = statusList.map((i, index) => {
      if (index === 0) {
        return {
          ...i,
          isActive: checked,
        }
      } else {
        return i
      }
    })
    setStatusList(updatedStatus)
  }

  const onCheckboxChange = (childItem, item, checked, isReset = false) => {
    const supporter = supporterNameList.find((i) => item?.type === `supporter-${i.id}`)
    const status = statusList.find((i) => childItem?.id === i?.id)

    const prop = {
      item,
      childItem,
      onNameChange,
      setOnNameChange,
      ownerList,
      ownerNameList,
      setOwnerNameList,
      supporterNameList,
      setSupporterNameList,
      supporter,
      ownerManagerList,
      setOwnerManagerList,
      setOwnersManagers,
      ownersManagers,
      ownerDepartmentList,
      setOwnerDepartmentList,
      setOwnersDepartmentIds,
      ownersDepartmentIds,
      setOwnersTeamIds,
      ownersTeamIds,
      typeDepartmentList,
      setTypeDepartmentList,
      setTypeDepartment,
      setTypeDepartments,
      typeDepartments,
      setVisibilityPublic,
      setVisibilityPrivate,
      priorityList,
      setPriorityList,
      tagFilterList,
      setTagFilterList,
      setAllActive,
      status,
      statusList,
      setStatusList,
      ownerTeamList,
      setOwnerTeamList,
      typeTeamList,
      setTypeTeamList,
      setTypeTeams,
      typeTeams,
      setTypeTeam,
      setHeaderLabel,
      isReset,
      setTypeAny,
      setTypeCompany,
      setTypeIndividual,
    }

    if (checked) {
      onFilterCheck(prop)
    } else {
      onFilterUncheck(prop)
    }
  }

  const removeSearchAction = () => {
    setSearchInput('')
    fetchGoalList()
  }

  const onFilterDate = () => {
    fetchGoalList()
    setShowDateModule(false)
  }

  const getDisplayName = (ownerEmp, user) => {
    if (ownerEmp) {
      if (ownerEmp === 'Anyone') {
        return 'Anyone'
      }
      return ownerEmp.name || ownerEmp.fullName
    }
    return user?.fullName
  }

  useEffect(() => {
    searchInput !== '' && setHeaderLabel({ name: 'Explore goals', title: '' })
  }, [searchInput])

  useEffect(() => {
    if (isDepartment && departmentId && departmentList) {
      let tempDepObj = departmentList.find((item) => item.id === parseInt(departmentId))
      tempDepObj = {
        ...tempDepObj,
        isActive: true,
      }
      onChipClose({ title: 'owner' })
      setHeaderLabel(tempDepObj)
      onCheckboxChange(tempDepObj, typeItems[2], true, true)
    }
  }, [isDepartment, departmentList])

  useEffect(() => {
    if (isTeam && teamId && teamList) {
      let tempDepObj = teamList.find((item) => item.id === parseInt(teamId))
      tempDepObj = {
        ...tempDepObj,
        isActive: true,
      }
      onChipClose({ title: 'owner' })
      setHeaderLabel(tempDepObj)
      onCheckboxChange(tempDepObj, typeItems[3], true, true)
    }
  }, [isTeam, teamList])

  useEffect(() => {
    if (isOwner) {
      if (curTab === 'Meetings') {
        navigate(`/meeting/${companyId}/user/${headerLabel.id}/?page=profile`, { replace: true })
      } else if (curTab === 'Overview') {
        navigate(`/people/${companyId}/user/${headerLabel.id}/overview/?page=profile`, { replace: true })
      } else if (curTab === 'Feedback') {
        navigate(`/people/${companyId}/user/${headerLabel.id}/feedback/?page=profile`, { replace: true })
      } else if (curTab === 'Tasks') {
        navigate(`/meeting/${companyId}/user/${headerLabel.id}/todo/assignments?page=profile`, { replace: true })
      }
    } else if (typeDepartment) {
      if (curTab === 'Overview') {
        navigate(`/people/${companyId}/department/${headerLabel.id}`, { replace: true })
      } else if (curTab === 'Meetings') {
        navigate(`/meeting/${companyId}/department/${headerLabel.id}`, { replace: true })
      } else if (curTab === 'Tasks') {
        navigate(`/meeting/${companyId}/user/${employeeId}/todo/assignments/dept/${headerLabel.id}`, { replace: true })
      }
    } else {
      if (curTab === 'Overview') {
        navigate(`/people/${companyId}/teams/${headerLabel.id}/members`, { replace: true })
      } else if (curTab === 'Meeting') {
        navigate(`/meeting/${companyId}/team/${headerLabel.id}`, { replace: true })
      } else if (curTab === 'Tasks') {
        navigate(`/meeting/${companyId}/user/${employeeId}/todo/assignments/team/${headerLabel?.id}`)
      }
    }
  }, [curTab])

  const containerRef = useRef(null)
  const buttonRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && buttonRef.current.contains(event.target)) return

      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setShowDateModule(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    setStartDate(INITIAL_DATE_RANGE.startDate)
    setDueDate(INITIAL_DATE_RANGE.dueDate)
  }, [])

  const responsiveClass = 'sm:max-w-172 lg:max-w-200 xl:max-w-256 ml-12 max-w-192'

  return (
    <DashboardLayout
      sidebarContent={sidebarContent({
        selected,
        setSelected,
        selectedCompany,
        ownerList,
        supporterList,
        ownerNameList,
        supporterNameList,
        onCheckboxChange,
        setAllActive,
        ownersItems,
        typeItems,
        supportersItems,
        statusItems,
        typeDepartmentList,
        typeTeamList,
        onChipClose,
        setHeaderLabel,
        rooms,
        showMoreOne,
        setShowMoreOne,
        typeCompany,
        setTypeCompany,
        setStartDate,
        setDueDate,
        setFilter,
        setOwnerNameList,
        setTypeTeams,
        setTypeTeam,
        setTypeDepartments,
        setTypeDepartment,
        setTypeIndividual,
        setTypeAny,
        numberOfEmployees,
        setSearchInput,
      })}
    >
      {!initGoal ? (
        exploreLoader()
      ) : (
        <>
          <div
            className="w-full h-24 fixed top-0"
            style={{ zIndex: 4000, background: 'linear-gradient(134deg, rgb(106, 152, 240) 0%, rgb(73, 97, 220) 99%)' }}
          >
            <div
              className={`${responsiveClass} fixed rounded-lg justify-start flex items-center mt-7`}
              style={{ zIndex: 1 }}
            >
              <Avatar
                className=""
                rectangle
                name={company?.companyName || 'Instinct Design Studio'}
                image={company?.companyThumbnail}
              />
              <h1 className="text-3xl text-white font-bold ml-4">
                {' '}
                {`Welcome back to ${
                  company?.companyName
                  // .split(' ')
                  // .map((name) => capitalize(name))
                  // .join(' ')
                }`}
              </h1>
            </div>
            <header
              style={{ marginTop: '96px', zIndex: 1, maxWidth: window.innerWidth, minWidth: '350px', width: '100%' }}
              className="flex items-center  bg-white"
            >
              <div
                style={{
                  backdropFilter: 'blur(16px)',
                  background: 'rgba(255,255,255, 0.5)',
                  boxShadow: '0 2px 6px 4px rgba(212, 212, 212, 0.44)',
                }}
                className={`flex ${responsiveClass} h-20 px-4 w-full transform rounded-lg translate-y-8 justify-between items-center`}
              >
                {fetching ? (
                  <div className="h-full flex items-center w-full justify-between">
                    <div className="flex items-center">
                      <div className="h-12 w-12 bg-gray-200 animate-pulse duration-700 rounded-full"></div>
                      <div className="h-10 w-28 mx-3 bg-gray-200 animate-pulse duration-700 rounded"></div>
                    </div>
                    <div className="h-8 w-96 bg-gray-200 animate-pulse duration-700 rounded"></div>
                  </div>
                ) : (
                  <>
                    <Avatar
                      className="h-full items-center"
                      nameClass="text-lg font-semibold "
                      name={headerLabel.name}
                      title={headerLabel?.title}
                      withName
                    />
                    {!(headerLabel.name === 'Explore goals' || typeCompany || isSubGoalPage) ? (
                      <Tabs2 todoItemsCount={itemsCount} tabs={tab} curTab={curTab} setCurTab={setCurTab} />
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </header>
          </div>
          <div className={`${responsiveClass} py-14 font-roboto`}>
            <div style={{ zIndex: 0, marginTop: '180px' }} className="flex flex-row relative space-x-4 items-center">
              <Button
                className="font-bold h-11 mt-6 w-80"
                iconClass="group-hover:text-white h-4 w-4"
                size="xl"
                hoverColor="bg-themeBlue"
                label={'Create goal'}
                primary
                onClick={onCreateGoal}
              />
              <SearchBar
                className="mt-6"
                autoCompleteOff
                Icon
                placeholder="Search goals"
                onCancel={removeSearchAction}
                onChange={setSearchInput}
                value={searchInput || ''}
              />
            </div>

            <div className="relative my-3 py-5 flex space-x-4 items-center flex-row">
              <div className="flex -ml-4">
                <div className="flex flex-col as-dropdown-container items-center gap-2">
                  <p className="text-gray-500 text-xs">Owner</p>
                  <AssignDropdown
                    header={
                      <div className="w-36 h-10 border border-gray-400 hover:border-blue-500 rounded-lg mt-5 grid px-2">
                        <p className="text-xs text-gray-500 self-center justify-self-center w-32 overflow-hidden overflow-ellipsis">
                          {getDisplayName(ownerEmp, user)}
                        </p>
                      </div>
                    }
                    ownerSearchInput={ownerSearchQuery}
                    setOwnerSearchInput={setOwnerSearchQuery}
                    ownerSuggestedList={employeeList?.map((employee) => ({
                      name: employee.fullName,
                      id: employee.id,
                      onClick: () => {
                        setOwnerSearchQuery('')
                        setOwnerEmp(employee)
                        setRefetch(true)
                      },
                    }))}
                    suggestionFetching={dropdownLoader}
                    type="filter"
                  />
                </div>
                <div className="flex flex-col as-dropdown-container items-center gap-2 -ml-4">
                  <p className="text-gray-500 text-xs">Supporters</p>
                  <AssignDropdown
                    header={
                      <div className="w-36 h-10 border border-gray-400 hover:border-blue-500 rounded-lg mt-5 grid px-2">
                        <p className="text-xs text-gray-500 self-center justify-self-center w-32 overflow-hidden overflow-ellipsis">
                          {supporterEmp?.fullName ? supporterEmp?.fullName : 'Anyone'}
                        </p>
                      </div>
                    }
                    ownerSearchInput={ownerSearchQuery}
                    setOwnerSearchInput={setOwnerSearchQuery}
                    ownerSuggestedList={employeeList?.map((employee) => ({
                      name: employee.fullName,
                      id: employee.id,
                      onClick: () => {
                        setOwnerSearchQuery('')
                        setSupporterEmp(employee)
                        setRefetch(true)
                      },
                    }))}
                    suggestionFetching={dropdownLoader}
                    type="filter"
                  />
                </div>
                <div className="flex flex-col items-center gap-2 mr-4">
                  <p className="text-gray-500 text-xs">Type</p>
                  <NestedDropdown2
                    chipList={chipList.filter((item) => item.title === 'type')}
                    items={typeItems}
                    Icon={BsFilter}
                    onCheckboxChange={onCheckboxChange}
                    label="Any"
                    closeBoxOnClick={true}
                  />
                </div>
                <div className="flex flex-col items-center gap-2">
                  <p className="text-gray-500 text-xs">Status</p>
                  <Dropdown3
                    chipList={chipList.filter((item) => item.title === 'status')}
                    items={statusItems}
                    Icon={BsFilter}
                    onCheckboxChange={onCheckboxChange}
                    label="Any"
                  />
                </div>
              </div>
              <div className="relative flex flex-col items-center gap-2 ">
                <p className="text-gray-500 text-xs">Due Date</p>
                <div
                  ref={buttonRef}
                  onClick={() => setShowDateModule((prev) => !prev)}
                  className="dateDropdown w-30 flex justify-center items-center text-xs bg-white dark:bg-gray-800 relative hover:border-blue-500 focus:border-blue-500 border border-gray-400 py-3 px-2 rounded-lg text-gray-700 hover:text-blue-500 cursor-pointer"
                >
                  {dueDate ? `${moment(startDate).format('MM/DD/YY')} - ${moment(dueDate).format('MM/DD/YY')}` : 'Any'}
                </div>
                <div style={{ zIndex: 5002 }} className="absolute top-full right-0">
                  {showDateModule && (
                    <div ref={containerRef} className="pl-4 mt-2 bg-white border-2 border-gray-300 p-4 rounded-lg">
                      <div className="flex gap-3">
                        <FaCalendarAlt size={24} className="text-gray-400" />
                        <div className="flex flex-col">
                          <h2 className="mb-2 font-semibold text-sm">Date Range</h2>
                          <div className="flex gap-3">
                            <DatePickerInput
                              placeholderText="Start Date"
                              className="w-30 text-sm py-2.5"
                              date={startDate}
                              setDate={setStartDate}
                              onKeyDown={(e) => {
                                e.preventDefault()
                              }}
                            />
                            <DatePickerInput
                              placeholderText="End Date"
                              className="w-30 text-sm py-2.5"
                              date={dueDate}
                              minDate={new Date(startDate)}
                              setDate={setDueDate}
                              onKeyDown={(e) => {
                                e.preventDefault()
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="flex space-x-4 mt-5 justify-end">
                        <Button
                          size="md"
                          btnColor="bg-themeRed"
                          hoverColor="bg-themeRed"
                          focusRing="ring-themeRed"
                          label={'Clear'}
                          secondary
                          onClick={() => {
                            setShowDateModule(false)
                            setStartDate(null)
                            setDueDate(null)
                            setRefetch(true)
                          }}
                        />
                        <Button size="md" primary label={'Apply'} disabled={dueDate === null} onClick={onFilterDate} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-center as-dropdown-container gap-2 -mt-3" style={{ marginLeft: 0 }}>
                <p className="text-gray-500 text-xs">Assessment Cycle</p>
                <AssignDropdown
                  header={
                    <div className="w-36 h-10 border border-gray-400 hover:border-blue-500 rounded-lg mt-5 grid px-2">
                      <p className="text-xs text-gray-500 self-center justify-self-center w-32 overflow-hidden overflow-ellipsis">
                        {selectedCycle ? selectedCycle?.name : 'Any'}
                      </p>
                    </div>
                  }
                  ownerSearchInput={cycleSearchInput}
                  setOwnerSearchInput={setCycleSearchInput}
                  ownerSuggestedList={assessmentCycleList?.map((cycle) => ({
                    name: cycle?.name,
                    id: cycle?.id,
                    onClick: () => {
                      setCycleSearchInput('')
                      setSelectedCycle(cycle)
                      setRefetch(true)
                    },
                  }))}
                  suggestionFetching={dropdownLoader}
                  type="assessment-filter"
                />
              </div>
            </div>

            <ExploreGoalList
              setSearchInput={setSearchInput}
              setFilter={setFilter}
              data={goals}
              setSelected={setSelected}
              chipClose={onChipClose}
              setAllActive={setAllActive}
              statusList={statusList}
              loading={fetching}
              setRefetch={setRefetch}
              ownerNameList={ownerNameList}
              setHeaderLabel={setHeaderLabel}
              setListType={setListType}
              selectedCompany={selectedCompany}
              setOwnerNameList={setOwnerNameList}
            />
            {!fetching && goals.length === 0 && (
              <div className="font-semibold flex justify-center my-14">
                <p>No goals have been created yet</p>
              </div>
            )}
            {!fetching && goals.length > 0 && (
              <Pagination pagination={pagination} size={size} onPageChange={onPageChange} />
            )}
          </div>
        </>
      )}
    </DashboardLayout>
  )
}

export default Explore
